import { ReactNode, lazy } from 'react';
import { AppstoreFilled, ShopFilled, ContainerFilled, SoundFilled, TagFilled, ContactsFilled, DashboardFilled } from '@ant-design/icons';
import Login from '@/pages/Login';
import InvalidPath from '@/pages/InvalidPath';
import { Permission_ENUM } from '@/const';

export const INDICATOR_VENDOR_ID = ':vendorId';
export const INDICATOR_FOOD_ID = ':foodId';

export const PATH_NO_MATCH = '*';
export const PATH_ROOT = '/';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_ORDER = '/order'
export const PATH_VENDOR = '/vendor';
export const PATH_VENDOR_DETAIL = `/vendor/detail/${INDICATOR_VENDOR_ID}`;
export const PATH_FOOD_DETAIL = `/vendor/food/${INDICATOR_VENDOR_ID}/${INDICATOR_FOOD_ID}`
export const PATH_RAIDER = '/raider';
export const PATH_CUSTOMER = '/customer';
export const PATH_EVENT = '/event';
export const PATH_PROMO_CODE = '/promo-code';

export interface RouteConfig {
  key: string;
  title: string;
  path: string;
  icon?: ReactNode;
  element: ReactNode;
  permissions: Permission_ENUM[];
  children?: RouteConfig[];
}

const Dashboard = lazy(() => import('@/pages/Dashboard'));
const Vendor = lazy(() => import('@/pages/Vendor'));
const VendorDetail = lazy(() => import('@/pages/VendorDetail'));
const FoodDetail = lazy(() => import('@/pages/FoodDetail'));
const Order = lazy(() => import('@/pages/Order'));
const Raider = lazy(() => import('@/pages/Raider'));
const Customer = lazy(() => import('@/pages/Customer'));
const Event = lazy(() => import('@/pages/Event'));
const PromoCode = lazy(() => import('@/pages/PromoCode'));

export const PUBLIC_ROUTES: RouteConfig[] = [
  {
    key: 'login',
    title: 'Login',
    path: PATH_ROOT,
    element: <Login />,
    permissions: [],
  },
];

export const VENDOR_ROUTE: RouteConfig = {
  key: 'vendor',
  title: 'Vendors',
  path: PATH_VENDOR,
  icon: <ShopFilled />,
  element: <Vendor />,
  permissions: [Permission_ENUM.VENDOR_READ],
};

export const CONTENT_ROUTES: RouteConfig[] = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: PATH_DASHBOARD,
    icon: <AppstoreFilled />,
    element: <Dashboard />,
    permissions: [],
  },
  {
    key: 'order',
    title: 'Orders',
    path: PATH_ORDER,
    icon: <ContainerFilled />,
    element: <Order />,
    permissions: [],
  },
  VENDOR_ROUTE,
  {
    key: 'raider',
    title: 'Raiders',
    path: PATH_RAIDER,
    icon: <DashboardFilled />,
    element: <Raider />,
    permissions: [],
  },
  {
    key: 'customer',
    title: 'Customers',
    path: PATH_CUSTOMER,
    icon: <ContactsFilled />,
    element: <Customer />,
    permissions: [],
  },
  {
    key: 'event',
    title: 'Events',
    path: PATH_EVENT,
    icon: <SoundFilled />,
    element: <Event />,
    permissions: [],
  },
  {
    key: 'promo-code',
    title: 'Promo Codes',
    path: PATH_PROMO_CODE,
    icon:<TagFilled />,
    element: <PromoCode />,
    permissions: [],
  },
  
];

export const NO_MATCH_ROUTE: RouteConfig = {
  key: 'nomatch',
  title: 'No Match',
  path: PATH_NO_MATCH,
  element: <InvalidPath />,
  permissions: [],
}

export const SUB_CONTENT_ROUTES: RouteConfig[] = [
  {
    key: 'vendor/detail',
    title: 'Vendor Detail',
    path: PATH_VENDOR_DETAIL,
    element: <VendorDetail />,
    permissions: [],
  },
  {
    key: 'food/detail',
    title: 'Food Detail',
    path: PATH_FOOD_DETAIL,
    icon:<TagFilled />,
    element: <FoodDetail />,
    permissions: [],
  }
];

export const flattenRoutes: (routes: RouteConfig[]) => RouteConfig[] = (
  routes,
) => {
  const temp: any[] = [];
  routes?.forEach((i) => {
      temp.push(i);
      (i?.children || []).forEach((s) => {
        temp.push(s);
      });
    });
  return temp;
};

export const PROTECTED_ROUTES: RouteConfig[] = [
  ...flattenRoutes(CONTENT_ROUTES),
  ...SUB_CONTENT_ROUTES,
];
