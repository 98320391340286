import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import SeLayout from '@/layout';

const SeContent: FC = () => {
  return (
    <SeLayout>
      <Suspense fallback={<Spin />}>
        <Outlet />
      </Suspense>
    </SeLayout>
  );
};

export default SeContent;
