import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import App from '@/app/App';
import './index.less';

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

const appComponent = (
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

app.render(appComponent);
