import { FC } from 'react';
import { Layout, Dropdown, Menu, MenuProps, Space } from 'antd';
import { LogoutOutlined, RobotFilled } from '@ant-design/icons';
import cx from 'classnames';
import { useAuth } from '@/context/auth';
import ClsGen from '@/utils/classname-generator';
import './index.less';

const { Header } = Layout;
const cls = ClsGen('se-header');

interface SeHeaderProps {
  className?: string;
}

const SeHeader: FC<SeHeaderProps> = ({ className = '' }) => {
  const { username, handleLogout } = useAuth();

  const handleProfileMenuClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'logout':
        handleLogout();
        break;
    }
  };

  // const profileMenu = (
  //   <Menu
  //     triggerSubMenuAction="click"
  //     onClick={handleProfileMenuClick}
  //     items={[
  //       {
  //         label: 'Log out',
  //         key: 'logout',
  //         icon: <LogoutOutlined />,
  //       },
  //     ]}
  //   />
  // );
  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'logout':
        handleLogout();
        break;
    }
  };
  const items: MenuProps['items'] = [
    {
      label: 'Log out',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Header className={cx(cls(''), className)}>
      <div className={cls('system')}>
        <div className={cls('system-logo')} />
        <div className={cls('system-name')}>ShareManage Web</div>
      </div>
      <div className={cls('admin')}>
        {/* <Dropdown
          className={cls('admin-menu')}
          overlay={profileMenu}
          trigger={['click']}
        >
          <div className={cls('admin-content')}>
            <div className={cls('admin-content-name')}>{username}</div>
            <RobotFilled className={cls('admin-content-icon')} />
          </div>
        </Dropdown> */}
        <Dropdown className={cls('admin-menu')} menu={{ items, onClick }} trigger={['click']}>

          <div className={cls('admin-content')}>
            <div className={cls('admin-content-name')}>{username}</div>
            <RobotFilled className={cls('admin-content-icon')} />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default SeHeader;
