import {
  FC,
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";

import axios from "axios";

enum AuthStatus_ENUM {
  VERIFYING = 0,
  VERIFIED = 1,
  NOT_AUTHORIZED = 2,
}
//declare type for the variables
interface AuthContextType {
  username: string | null;
  isLoggedIn: boolean;
  authLoginStatus: boolean;
  isNotAuthorized: boolean;
  handleAuthStatus: () => void;
  handleLogin: (username: string, password: string) => void;
  handleLogout: () => void;
}
//iniatia global variable
const AuthContext = createContext<AuthContextType>({
  username: null,
  isLoggedIn: false,
  authLoginStatus: true,
  isNotAuthorized: false,
  handleAuthStatus: () => {},
  handleLogin: (username: string, password: string) => {},
  handleLogout: () => {},
});

interface Props {
  children: any;
}
const baseURL = "https://api-testnet.sharemanage.com/login";

const AuthProvider: FC<Props> = ({ children }) => {
  const [username, setUsername] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authLoginStatus, setAuthLoginStatus] = useState(false);
  const [authStatus, setAuthStatus] = useState(AuthStatus_ENUM.VERIFYING);

  const isNotAuthorized = useMemo(() => authStatus === AuthStatus_ENUM.NOT_AUTHORIZED, [authStatus]);


  const handleLogin = (username: string, password: string) => {
    let data = JSON.stringify({
      username,
      password,
    });

    setAuthStatus(AuthStatus_ENUM.VERIFYING);

    axios
      .post(baseURL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        // setPost(response.data);
        console.log(response.data.code);
        if (response.data.code === 0) {
          localStorage.setItem("isLoggedIn", "1");
          localStorage.setItem("username", username);
          setIsLoggedIn(true);
          setUsername(username);
          setAuthLoginStatus(true);
          setAuthStatus(AuthStatus_ENUM.VERIFIED);
        } else {
          //invalid login
          setAuthLoginStatus(false);
          setAuthStatus(AuthStatus_ENUM.NOT_AUTHORIZED);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  const handleAuthStatus = () => {
    setAuthLoginStatus(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");
    setIsLoggedIn(false);
    setAuthStatus(AuthStatus_ENUM.NOT_AUTHORIZED);
  };

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");
    const isLoggedIn = storedUserLoggedInInformation === "1";
    setIsLoggedIn(isLoggedIn);

    if (isLoggedIn) {
      const _username = localStorage.getItem("username");
      _username && setUsername(_username);
      setAuthStatus(AuthStatus_ENUM.VERIFIED);
      return;
    }

    setAuthStatus(AuthStatus_ENUM.NOT_AUTHORIZED);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        username,
        isLoggedIn,
        authLoginStatus,
        isNotAuthorized,
        handleAuthStatus,
        handleLogin,
        handleLogout,
      }}
    >
      {useMemo(() => children, [username, isLoggedIn, authLoginStatus, authStatus])}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };
