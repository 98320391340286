import { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/auth';
import { PATH_ROOT } from '@/routes';

interface RequireAuthProps {
  children: ReactNode;
}

const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const { isNotAuthorized } = useAuth();
  const location = useLocation();

  if (isNotAuthorized) {
    return <Navigate to={PATH_ROOT} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
