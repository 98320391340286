import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '@/context/auth';
import { PATH_DASHBOARD } from '@/routes';
import './index.less';

const Login: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { authLoginStatus, isLoggedIn, handleLogin, handleAuthStatus } = useAuth();

  const from = ((state as any)?.from || {}) as Location;
  const fromPath =  from?.pathname + from?.search + from?.hash || PATH_DASHBOARD;

  const onFinish = (values: any) => {
    console.log(values.username);
    console.log("Success:", values);
    handleLogin(values.username, values.password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const clearMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Change:", e.target.value);
    if (e.target.value.length !== 0) {
      handleAuthStatus();
    }
  };

  useEffect(() => {
    isLoggedIn && navigate(fromPath, { replace: true });
  }, [isLoggedIn]);

  return (
    <div className="bg_color">
      <div className="bg_image">
        <div className="center">
          <div className="title">ShareManage</div>
          <span className="small_title">Welcome back, let share happiness.</span>
        </div>
        <div className="form_container">
          <div className="login">Login</div>

          {authLoginStatus && (
            <Alert
              message="Username / password invalid!"
              type="error"
              showIcon
            />
          )}

          <Form
            className="form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
              onChange={clearMessage}
                placeholder="Username"
                prefix={<UserOutlined className="site-form-item-icon icon" />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<LockOutlined className="site-form-item-icon icon" />}
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit" size="large">
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="footer">
        Copyright ©2022 Authorized by ShareGree Pte. Ltd.
      </div>
    </div>
  );
};

export default Login;
