import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import { RouteConfig, CONTENT_ROUTES } from '@/routes';
import ClsGen from '@/utils/classname-generator';
import { hasPermission } from '@/utils/permission';
import './index.less';

const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const cls = ClsGen('se-sidebar');

interface SeSidebarProps {
  collapsed: boolean;
  onToggle: (v: boolean) => void;
}

const SeSidebar: FC<SeSidebarProps> = ({collapsed, onToggle}) => {
  const { pathname } = useLocation();

  const getAccessibleRoutes: (routeConfig: RouteConfig[]) => RouteConfig[] = (routeConfigs) => routeConfigs?.filter(rc => hasPermission(rc.permissions)) || [];

  const genMenuItem: (routeConfig: RouteConfig) => MenuItem = (routeConfig) => {
    const children = getAccessibleRoutes(routeConfig?.children)?.map(rc => genMenuItem(rc));
    return {
      key: routeConfig?.key,
      icon: routeConfig?.icon,
      label: <Link to={routeConfig.path}>{routeConfig.title}</Link>,
      ...children?.length > 0 && children,
    }
  };

  const menuItems: MenuItem[] = getAccessibleRoutes(CONTENT_ROUTES).map((routeConfig) => genMenuItem(routeConfig));

  return (
    <Sider
      className={cls('')}
      collapsible
      collapsed={collapsed}
      onCollapse={onToggle}
      width={200}
    >
      <Menu theme="dark" mode="inline" selectedKeys={[pathname, ...pathname?.split('/')]} items={menuItems} />
    </Sider>
  );
};

export default SeSidebar;
