import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  PROTECTED_ROUTES,
  PUBLIC_ROUTES,
  NO_MATCH_ROUTE,
} from '@/routes';
import SeContent from '@/components/se/content';
import RequireAuth from '@/components/required-auth';
import { hasPermission } from '@/utils/permission';
import clsGen from '@/utils/classname-generator';
import './App.less';

const cls = clsGen('main');

const App: FC = () => {

  return (
    <main className={cls('')}>
      <Routes>
        {PUBLIC_ROUTES.map((routeConfig) => (
          <Route
            key={routeConfig.key}
            path={routeConfig.path}
            element={routeConfig.element}
          />
        ))}
        <Route element={<SeContent />}>
          {PROTECTED_ROUTES.map(
            (routeConfig) =>
              hasPermission(routeConfig.permissions) && (
                <Route
                  key={routeConfig.key}
                  path={routeConfig.path}
                  element={<RequireAuth>{routeConfig.element}</RequireAuth>}
                />
              ),
          )}
        </Route>
        <Route 
          key={NO_MATCH_ROUTE.key}
          path={NO_MATCH_ROUTE.path}
          element={NO_MATCH_ROUTE.element}
        />
      </Routes>
    </main>
  );
};

export default App;
