import { FC, ReactNode, useState } from 'react';
import { Layout } from 'antd';
import cx from 'classnames';
import SeHeader from './header';
import SeSidebar from './sidebar';
import ClsGen from '@/utils/classname-generator';
import './index.less';

const cls = ClsGen('se-layout');

const { Header, Content } = Layout;

interface SeLayoutProps {
  children?: ReactNode;
}

const SeLayout: FC<SeLayoutProps> = ({ children }) => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const _onSiderToggle = () => {
    setSiderCollapsed((preState) => !preState);
  };

  return (
    <Layout className={cls('')}>
      <SeHeader />
      <Layout className={cls('body')}>
        <SeSidebar collapsed={siderCollapsed} onToggle={_onSiderToggle} />
        <Layout
          className={cx(cls('content'), {
            expanded: siderCollapsed,
          })}
        >
          <Content className={cls('content-wrapper')}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SeLayout;
